import styled from 'styled-components';

import { colors, medias } from 'assets/styled/tokens';

export const StyledMenu = styled.div`
  width: 88px;
  height: 100%;
  padding: 1.5rem 1rem;
  background-color: ${colors.white};
  box-shadow: 0px 1px 8px 0px #00000026;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 99;
  position: fixed;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const StyledLogo = styled.div`
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    display: inline-block;
    transition: opacity 1s ease-in-out;
    &::webkit-transition {
      opacity: 1s ease-in-out;
    }
  }
  svg {
    cursor: pointer;
  }

  @media screen and (max-width: ${medias.desktopSm}) {
    width: 48px;
    height: 48px;
    img {
      height: 48px;
    }
  }
  @media screen and (max-width: ${medias.desktopXl}) {
    width: 56px;
    height: 56px;
    img {
      height: 56px;
    }
  }
`;

export const StyledNav = styled.nav`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  flex: 1;
  margin: 16px -1rem;
`;
export const StyledMenuCategory = styled.ul`
  & + ul {
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid ${colors.whiteGray};
  }
`;
