import { CircleBody } from './styled';
import { ReactComponent as AttentionIcon } from 'assets/icons/red-atention-icon.svg';
export const CircleInfo = ({ ocorrencias, chartData, text }) => {
  const getBackgroundColor = () => {
    if (text === 'realizadas' || text === 'fechadas' || chartData === 0) {
      return '#00A889'; // Set background color to green
    } else if (text === 'abertas' && ocorrencias && chartData > 0) {
      return '#EC6655'; // Set background color to red
    } else if (text === 'pendentes' && !ocorrencias) {
      return '#F38A00'; // Set background color to orange
    }
  };
  const showIcon = ocorrencias && text === 'abertas' && chartData > 0;
  return (
    <CircleBody color={getBackgroundColor()}>
      {showIcon && <AttentionIcon />}
      <h1>{chartData.toLocaleString('pt-BR', { style: 'decimal', minimumFractionDigits: 0 })}</h1>
      <h2>{text}</h2>
    </CircleBody>
  );
};
